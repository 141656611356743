import { useContext, useState } from 'react';
// import useFetch from 'renderer/hooks/useFetch';
import { AppContext, AppContextProvider } from './AppContext';
import jwt from '../jwtservice/jwtService';
import { useNavigate } from 'react-router-dom';

const AppContextContainer = ({ children }) => {
  const [activeTab, setActiveTab] = useState("TopupCard");

  const navigate = useNavigate();

  // useEffect(() => {
  //   addPaymentType('');
  //   internetConnection();
  //   // listenCard();
  //   const isLogin = localStorage.getItem('isLogin');
  //   if (!isLogin || isLogin === false || isLogin === 'false')
  //     navigate('/login', { replace: true });
  //   else navigate('/dashboard', { replace: true });
  // }, []);

  const handleActiveTab = (value) => {
    setActiveTab(value);
  };

  const routeDashboard = async (id) => {
    try {
      await jwt.getRfidCardCredit(id);
      navigate('/transaction', { state: { id } })
    } catch (e) {
      const { statusCode } = e.response?.data
      navigate('/error-page', { state: { statusCode } })
    }
  }

  // const getItems = () => {
  //   setIsItemsLoading(true);
  //   jwt
  //     .getAllEvents()
  //     .then((res) => {
  //       console.log('getItems called');
  //       res.data.events && setEvents(res.data.events);
  //       res.data.products && setItems(res.data.products);
  //       setIsEventsLoading(false);
  //       setIsItemsLoading(false);
  //       setRoutesUsed([]);

  //       const men = JSON.parse(localStorage.getItem('user')).location.menus;
  //       if (men.length > 0) {
  //         let newRoutes = [];
  //         setRoutesUsed((prev) => {
  //           ROUTES.find((route) => {
  //             men.includes(route.title) && newRoutes.push(route);
  //           });
  //           setMenus(men);
  //           return newRoutes;
  //         });
  //       }

  //       placeOrderBulk();
  //     })
  //     .catch((e) => {
  //       if (JSON.stringify(e).includes('401')) {
  //         localStorage.setItem('isLogin', false);
  //         navigate('/login');
  //       }
  //     });
  // };

  const contextValue = {
    activeTab,
    setActiveTab,
    handleActiveTab,
    routeDashboard
  };

  return (
    <AppContextProvider value={contextValue}>{children}</AppContextProvider>
  );
};

export default AppContextContainer;

export const useAppContext = () => useContext(AppContext);
